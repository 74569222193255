/*
 * Copyright (C) 2023 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Container, styled, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';

import Layout from 'components/partials/Layout';
import { Button } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const StaysSection = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(25),
  marginTop: theme.spacing(25),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'flex',
    marginTop: theme.spacing(40),
  },
}));

const StaysContainer = styled(Container)({
  position: 'relative',
});

const Content = styled('div')({
  margin: '0 auto',
  textAlign: 'center',
});

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(35),
  fontWeight: 600,
  lineHeight: theme.typography.pxToRem(35),
  marginBottom: theme.typography.pxToRem(48),
  textAlign: 'center',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    fontSize: theme.typography.pxToRem(60),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(50),
  },
}));

const BodyText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up(Breakpoints.Md)]: {},
}));

const TextWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: {},
}));

const Stays: React.FC<StaysProps> = ({ data }) => {
  return (
    <Layout
      title={data.staysYaml.metadata.title}
      description={data.staysYaml.metadata.description}
    >
      <StaysSection>
        <StaysContainer>
          <Content>
            <Title>{data.staysYaml.text.title}</Title>
            <TextWrapper>
              <BodyText>{data.staysYaml.text.firstLine}</BodyText>
              <BodyText>{data.staysYaml.text.secondLine}</BodyText>
            </TextWrapper>

            <ButtonWrapper>
              <Button to={data.staysYaml.button.url}>
                {data.staysYaml.button.text}
              </Button>
            </ButtonWrapper>
          </Content>
        </StaysContainer>
      </StaysSection>
    </Layout>
  );
};

interface StaysProps {
  data: {
    staysYaml: {
      metadata: {
        title: string;
        description: string;
      };
      text: {
        title: string;
        firstLine: string;
        secondLine: string;
      };
      button: {
        text: string;
        url: string;
      };
    };
  };
}

export const query = graphql`
  query StaysQuery {
    staysYaml {
      metadata {
        description
        title
      }
      text {
        title
        firstLine
        secondLine
      }
      button {
        text
        url
      }
    }
  }
`;

export default Stays;
